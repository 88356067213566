<template>
  <div class="address">
    <ShopNavBar title="修改地址" />
  
    <div class="form">
      <van-field v-model="formData.username" label="姓名" placeholder="请输入姓名" />
      <van-field v-model="formData.usertel" label="手机号" placeholder="请输入手机号" />
      <van-field v-model="formData.showArea" label="地区" placeholder="请选择地区" readonly @click="show = true" />
      <van-field v-model="formData.address" label="详情地址" placeholder="请输入详情地址" />
    </div>

    <div class="default-box">
			<div>设为默认收货地址</div>
			<van-switch v-model="formData.isdefu" active-color="#ff2d2e" size="24px" />
		</div>

    <div class="btn" @click="submit">保存</div>
    <div class="btn del-btn" @click="del">删除</div>

    <van-popup v-model:show="show" position="bottom">
      <van-area :value="formData.areaCode" title="选择地址" confirm-button-text="确定" cancel-button-text="取消" :area-list="areaList"
        @confirm="confirm" @cancel="show = false" />
    </van-popup>
  </div>
</template>

<script>
import { NavBar, Field, Popup, Picker, Area, Switch, Toast } from 'vant';
import { ref } from 'vue';

import areaList from "@/assets/js/area.js";

export default {
  components: {
    [NavBar.name]: NavBar,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Area.name]: Area,
    [Switch.name]: Switch,
  },
  setup() {
    return {
      us: ref(null),
      formData: ref({
        username: "",
        usertel: "",
        address: "",
        sheng: "",
        shi: "",
        xian: "",
        areaCode: "",
        showArea: "",
        isdefu: false
      }),
      show: ref(false),
      areaList,
      aid: ref("")
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    this.aid = this.$route.query.id
    this.getdata()
  },
  methods: {
    onClickLeft() {
      this.$router.back()
    },
    confirm(e) {
      this.formData.sheng = e[0].name,
      this.formData.shi = e[1].name
      this.formData.xian = e[2].name
      this.formData.areaCode = e[2].code
      this.formData.showArea = e[0].name + e[1].name + e[2].name
      this.show = false
    },
    getdata() {
      let _this = this
      _this.$request.post(
        "api/UsersAddress/Get",
        {
          userid: _this.us.userid,
          aid: _this.aid
        },
        (res) => {
          if(res.data.code == 100) {
            let data = res.data.data
            data.showArea = data.sheng + data.shi + data.xian
            data.isdefu = data.isdefault == 1
            _this.formData = data
          }
        }
      )
    },
    submit() {
      let _this = this
      let formData = this.formData
      if(formData.username == '') {
        Toast('请输入姓名');
        return
      }
      if(formData.usertel == '') {
        Toast('请输入手机号');
        return
      }
      if(formData.areaCode == '') {
        Toast('请选择地区');
        return
      }
      if(formData.address == '') {
        Toast('请输入详情地址');
        return
      }
      _this.$request.post(
        "api/UsersAddress/Update",
        {
          userid: _this.us.userid,
          aid: _this.aid,
					username: formData.username,
					usertel: formData.usertel,
					sheng: formData.sheng,
					shi: formData.shi,
					xian: formData.xian,
					address: formData.address,
					isdefault: formData.isdefu,
					areaCode: formData.areaCode,
        },
        (res) => {
          _this.$q.dialog({
            message: res.data.msg,
          })
        }
      )
    },
    del() {
      let _this = this
      _this.$q.dialog({
        message: "确定要删除改地址吗？",
        cancel: true,
      }).onOk(() => {
        _this.$request.post(
          "api/UsersAddress/Delete",
          {
            
            userid: _this.us.userid,
            delete_id: _this.aid,
          },
          (res) => {
            _this.$q.dialog({
              message: res.data.msg,
            }).onOk(() => {
              if(res.data.code == 100) {
                _this.$router.back()
              }
            })
          }
        )
      })
    }
  }
}
</script>

<style scoped>
.address {
  min-height: 100vh;
  box-sizing: border-box;
  background: rgba(242, 242, 242, 1);
}

.form {
  margin: 10px;
  padding: 5px;
  border-radius: 10px;
  background-color: #fff;
}

.default-box {
	margin: 15px 10px 0;
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 10px;
	background-color: #fff;
}

.btn {
  margin: 20px 10px 0;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  border-radius: 46px;
  background: linear-gradient(90deg, rgba(248, 18, 75, 1) 0%, rgba(254, 44, 45, 1) 100%);
}

.del-btn {
  background: #ffc107;
}
</style>